import React, { useCallback, useState } from "react";
import Layout from "../layouts/Layout";
import styled from "styled-components";
import MetodoPiuVeloce from "../sections/MetodoPiuVeloce";
import { StaticImage } from "gatsby-plugin-image";
import { Link, navigate } from "gatsby";
import Axios from 'axios'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const StyledStaticPage = styled.div`
  .static-page {
    &__title {
      text-align: center;
      @media (min-width: 992px) {
        /* padding-top: 200px; */
      }
    }

    &__slug {
      @media (min-width: 992px) {
        max-width: 50%;
      }
      a {
        text-decoration: none;
        color: #000;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__header {
      background-color: #fff;

      @media (min-width: 992px) {
        background: -webkit-linear-gradient(
          273deg,
          rgba(242, 242, 242, 0.7) 0%,
          rgba(206, 212, 216, 0.7) 65%,
          rgba(206, 212, 216, 0.698) 100%
        );
        background: -o-linear-gradient(
          273deg,
          rgba(242, 242, 242, 0.7) 0%,
          rgba(206, 212, 216, 0.7) 65%,
          rgba(206, 212, 216, 0.698) 100%
        );
        background: -ms-linear-gradient(
          273deg,
          rgba(242, 242, 242, 0.7) 0%,
          rgba(206, 212, 216, 0.7) 65%,
          rgba(206, 212, 216, 0.698) 100%
        );
        background: -moz-linear-gradient(
          273deg,
          rgba(242, 242, 242, 0.7) 0%,
          rgba(206, 212, 216, 0.7) 65%,
          rgba(206, 212, 216, 0.698) 100%
        );
        background: linear-gradient(
          177deg,
          rgba(242, 242, 242, 0.7) 0%,
          rgba(206, 212, 216, 0.7) 65%,
          rgba(206, 212, 216, 0.698) 100%
        );
        height: 300px;
      }

      &__description {
        text-align: center;

        @media (min-width: 992px) {
          text-align: left;
          margin-left: 20px;
          max-width: 45%;
        }

        @media (min-width: 992px) {
          height: 300px;
        }
      }

      .intro {
        color: #527191;
      }

      .title {
        padding-left: 3em;
      }
    }
  }

  .wrapper {
    height: 100%;
    max-width: 1440px;
    justify-content: center;

    @media (min-width: 768px) {
      justify-content: space-between;
    }
    &__image-description {
      height: 100%;
      width: 100%;
      align-content: center;
      align-items: center;
    }
  }

  .form-multi-step {
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 1;
  }

  .section {
    display: flex;
    align-items: center;
    @media (max-width: 992px) {
      flex-direction: column;
    }
    &__title {
      color: #e7c700;
      font-size: 30px;
      min-width: 400px;
      align-self: flex-start;
      text-align: left;
      padding-top: 1em;
    }
  }
  .html_form {
    input:not([type="checkbox"]),
    select {
      height: 55px !important;
    }
    .form-check-input {
      margin-right: 10px;
    }
  }
`;

export default function Contattaci({ className }) {

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [error, setError] = useState(false);

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('yourAction');

    return token
    // Do whatever you want with the token
  }, [executeRecaptcha]);


  const handleSubmit = async e => {
    e.preventDefault()
    const token = await handleReCaptchaVerify()

    const formData = new FormData(e.target)
    formData.append('token', token)

    const data = new URLSearchParams(formData.entries())

    Axios.post(e.target.action, data)
      .then(res => {
        if (res.data === 'OK') {
          navigate('/thank-you-contattaci');
        } else {
          setError(true)
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LfXNVMdAAAAAJuGQT2ZOV0AcdsOVcUitc7k79Nb"
      scriptProps={{ defer: true }}
    >
      <Layout helmetTitle="Contattaci">
        <StyledStaticPage id="page-content">
          <div className="static-page__header">
            <div className="wrapper container d-flex align-items-center position-relative">
              <div className="d-flex align-items-center flex-column-reverse flex-lg-row wrapper__image-description">
                <StaticImage
                  src="../assets/images/testatina-contattaci.png"
                  className={className}
                  alt="Contattaci"
                  placeholder="blurred"
                  layout="fixed"
                  width={203}
                  height={150}
                  objectFit="contain"
                  quality={75}
                />
                <div className="d-flex flex-column static-page__header__description justify-content-center pb-3 pb-lg-0">
                  <h1 className="fw-bold">Contattaci</h1>
                  <span className="intro">
                    {/* Lorem ipsum dolor sit amet consectuetur adipiscing elit */}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div id="page-content" className="container mx-auto">
            <h5 className="static-page__slug mb-5 mt-4">
              {" "}
              <Link to="/" className="fw-bold">
                HOME
              </Link>{" "}
              &gt; <Link to="/contattaci/">Contattaci</Link>
            </h5>
            <h5 className="static-page__title mb-5 display-5 fw-bold">
              Contattaci
            </h5>
            <div className="static-page__content container-1440">
              <p className="mb-5">
                Richiedi maggiori informazioni su cessione del quinto, prestiti,
                mutui e sugli altri servizi Prestitifaidate.it. Compila e invia la
                tua richiesta: insieme, troveremo la soluzione fatta su misura per
                te. Energia e intraprendenza: sono queste le doti che portano i
                professionisti di Prestitifaidate.it a essere vincenti. Per
                entrare a far parte del nostro team, compila e invia la tua
                richiesta candidatura.
              </p>
              <form
                className="html_form row"
                method="POST"
                action={process.env.GATSBY_FORM_CONNECTOR_ENDPOINT}
                onSubmit={handleSubmit}
              >
                <div className="col-md-6 form-floating">
                  <input
                    type="text"
                    className="form-control"
                    name="first_name"
                    id="first_name"
                    placeholder="Nome*"
                    maxLength="255"
                    required="required"
                  />
                  <label htmlFor="first_name">Nome*</label>
                </div>
                <div className="col-md-6 form-floating">
                  <input
                    type="text"
                    className="form-control"
                    name="last_name"
                    id="last_name"
                    placeholder="Cognome*"
                    maxLength="255"
                    required="required"
                  />
                  <label htmlFor="last_name">Cognome*</label>
                </div>
                <div className="col-md-6 form-floating">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Email*"
                    maxLength="255"
                    required="required"
                  />
                  <label htmlFor="email">Email*</label>
                </div>
                <div className="col-md-6 form-floating">
                  <input
                    type="text"
                    className="form-control"
                    name="telephone"
                    id="telephone"
                    maxLength="255"
                    placeholder="Recapito telefonico"
                  />
                  <label htmlFor="telephone">Recapito telefonico</label>
                </div>

                <div className="col-12 form-floating">
                  <select
                    name="reason"
                    id="reason"
                    className="form-select"
                    defaultValue=""
                    required="required"
                  >
                    <option value="" disabled>
                      Seleziona qui
                    </option>
                    <option value="Cessione del Quinto">
                      Cessione del Quinto
                    </option>
                    <option value="Prestiti Personali">Prestiti Personali</option>
                    <option value="Finanziamenti">Finanziamenti</option>
                    <option value="Mutui casa">Mutui casa</option>
                    <option value="" disabled>
                      --
                    </option>
                    <option value="Richiesta informazioni">
                      Richiesta informazioni
                    </option>
                  </select>
                  <label htmlFor="reason">Di cosa hai bisogno?*</label>
                </div>

                <div className="col-12 form-floating">
                  <textarea
                    className="form-control"
                    name="message"
                    id="message"
                    rows="3"
                    placeholder="Messaggio*"
                    required="required"
                    style={{ height: 150 }}
                  ></textarea>
                  <label htmlFor="message">Messaggio*</label>
                </div>

                <div className="col-12 form-check form-switch">
                  <input
                    className="form-check-input mr-1"
                    name="checkbox_privacy"
                    type="checkbox"
                    id="checkbox_privacy"
                    required="required"
                  />
                  <label className="form-check-label" htmlFor="checkbox_privacy">
                    Accetta la normativa{" "}
                    <a
                      href="https://www.iusprivacy.eu/informativa-privacy-info-1005616530"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy
                    </a>
                  </label>
                </div>

                <div className="col-12 text-center">
                  <button
                    type="submit"
                    className="btn mt-1 rounded-pill btn-outline-dark text-uppercase"
                  >
                    Invia il tuo messaggio
                  </button>
                </div>
              </form>
              {
                error && <div className="alert alert-danger mt-5 fw-bold" role="alert">
                  Oops, qualcosa è andato storto. Per favore, riprova.
                </div>
              }
            </div>
          </div>
        </StyledStaticPage>
        <MetodoPiuVeloce className="mt-5" />
      </Layout>
    </GoogleReCaptchaProvider>
  );
}
